import { Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';

interface SuccessAndErrorProps {
  shippingErrors?: string[];
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 2rem;
  @media ${mobile} {
    margin-bottom: 0;
  }
`;

const Title = styled(Text)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #564789;
  margin-bottom: 9px;
  max-width: 320px;
`;

const Error = styled(Title)`
  color: #ee5959;
`;

const Label = styled(Text)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 320px;
  span {
    font-weight: 800;
  }
`;

const SuccessAndError: FC<SuccessAndErrorProps> = ({ shippingErrors }) => (
  <Container>
    {shippingErrors?.length ? (
      shippingErrors.map(error => <Error>{error}</Error>)
    ) : (
      <Title>You have successfully entered your shipping address!</Title>
    )}
    {shippingErrors?.length ? (
      <Label>
        Please send an email with your shipping address to
        <span> help@raising.dog</span>
      </Label>
    ) : (
      <Label>
        We will notify you via email once the order has been shipped. You may
        now close the window.
      </Label>
    )}
  </Container>
);

export default SuccessAndError;
