import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { Svg } from 'components';
import ArrowDown from '../../../../assets/icons/arrow-down-select.svg';

interface IStates {
  value: string;
  label: string;
}

interface ISelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<IStates>;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  name: string;
  value?: string;
  label?: string;
  error?: string | boolean;
}

export const Select: React.FC<ISelect> = ({
  options,
  placeholder,
  value,
  name,
  error = false,
  ...rest
}) => (
  <SelectContainer>
    <ArrowContainer>
      <StyledArrowDown />
    </ArrowContainer>

    <SelectElement
      className={value && 'selected'}
      data-testid={name}
      placeholder={placeholder}
      id={name}
      value={value}
      {...rest}
      error={!!error}
    >
      {!value && (
        <Option disabled selected value="">
          {placeholder}
        </Option>
      )}

      {options.map(option => (
        <option key={`${option.value} ${option.label}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectElement>
    {error && { error }}
  </SelectContainer>
);

const ArrowContainer = styled.div`
  position: absolute;
  pointer-events: none;
  align-items: center;
  display: flex;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledArrowDown = styled(ArrowDown)`
  margin-right: 0.5rem;
`;

const SelectElement = styled.select<{ error: boolean; value: string }>`
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
  border-radius: 8px;
  padding: 0.875rem 0.5rem;
  border: 1px solid rgb(228, 228, 235);
  background: #f9f9f9;
  color: ${({ value, theme }) => (value ? 'initial' : theme.colors.dark10)};
`;

const Option = styled.option`
  color: ${({ theme }) => theme.colors.dark10};
`;
