import React, { FC, useMemo } from 'react';
import Lottie, { Options } from 'react-lottie';
import styled from 'styled-components';

import loadingAnimation from 'assets/lotties/calculating.json';
import { mobile } from 'styles/breakpoints';

const Container = styled.div`
  height: 18.25rem;

  @media ${mobile} {
    height: 13rem;
  }
`;

const Loader: FC = () => {
  const options: Options = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    }),
    [],
  );

  return (
    <Container>
      <Lottie options={options} isClickToPauseDisabled />
    </Container>
  );
};

export default React.memo(Loader);
