import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { handleError } from 'utils/error';
import styled from 'styled-components';
import { config } from 'config';

export interface AddressAutocompleteProps {
  value: string;
  placeholder?: string | null;
  errorMsg?: string | null;
  isError?: boolean;
  styles: any;
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  dataTestId?: string;
}

export const AddressAutocomplete: React.FC<
  AddressAutocompleteProps
> = props => {
  const { value, errorMsg, placeholder, onChange, onSelect, isError, styles } =
    props;

  const [key, setKey] = useState(new Date().getTime());
  const [isFocused, setIsFocused] = useState(false);
  const isAddressError = !!errorMsg || isError;

  const handleInputChange = (
    inputValue: string,
    { action }: { action: string },
  ) => {
    if (action === 'input-change') {
      onChange(inputValue);
    }
  };

  const handleSelect = (option: { label: string }) => {
    if (!option?.label) {
      return;
    }

    onSelect(option.label);
    setKey(new Date().getTime());
  };

  return (
    <StyledBox>
      <LabelContainer>{!value && <Label />}</LabelContainer>
      <GooglePlacesAutocomplete
        apiKey={config.GOOGLE_LOCATION_KEY}
        apiOptions={{ language: 'en' }}
        onLoadFailed={error => handleError(error)}
        autocompletionRequest={{
          types: ['address'],
        }}
        selectProps={{
          key,
          value: value ? { value, label: value } : '',
          inputValue: value,
          name: 'street-address',
          placeholder: '',
          onFocus: () => setIsFocused(true),
          onBlur: () => setIsFocused(false),
          onInputChange: handleInputChange,
          onChange: handleSelect,
          styles: styles,
          noOptionsMessage: () => null,
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
        }}
      />
    </StyledBox>
  );
};

const StyledBox = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  position: absolute;
  pointer-events: none;

  z-index: 2;
  padding: 13px 0.65rem;
`;

const Label = styled.label`
  font-size: 1rem;
  pointer-events: none;
  color: ${({ theme }) => theme.colors.dark10};
  :before {
    content: 'Street address or P.O. Box';
  }
`;
